export const BASE_ROUTER = {
    HOME: '/',
    TABLE: '/table',
    ABOUT: '/about',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    REQUEST_ACCOUNT: '/request-account',
    TABLE_BILL: '/table/bill',
    TABLE_Order: '/table/order',
    TABLE_BILL_CHECKOUT: '/table/bill/split',
    BILL_DETAIL: '/bills/detail',
    404: '/404',
    SETTINGS: '/settings',
    BILL: '/bills',
    ORDERS: '/orders',
    SETTINGS_PROFILE: '/settings/Profile',
    SETTINGS_PASSWORD: '/settings/Password',
    SETTINGS_PRINTER: '/settings/Printer',
    RESTAURENT_MANAGER: '/restaurent/manager',
    RESTAURENT_Location: '/restaurent/location',
    RESTAURENT_Opening: '/restaurent/opening',
    RESTAURENT_Tip: '/restaurent/tip',
    MENU_MANAGER: '/menu-manager',
    PROMOTIONS: '/promotions',
    CUSTOMERS_INFORMATION: '/customers-information',
    CUSTOMER_NEW: '/customer/new',
    CUSTOMER_LIST: '/customer/list',
    CUSTOMER_Detail: '/customer/detail',
    RESTAURENT_RESERVATION: '/restaurent/reservation',
    RESTAURENT_KITCHEN_STATION: '/restaurent/kitchenStation',
    RESTAURENT_KITCHEN_STATION_DETAIL: '/restaurent/kitchenStation/Detail',
};
